import React, { useEffect, useMemo } from "react";
import "./css/main.css";
import "./css/lineicons.css";
import PartOne from "./PartOne";
import PartTwo from "./PartTwo";
import Button from "@mui/material/Button";
import PartThree from "./PartThree";
import PartFour from "./PartFour";
import PartFive from "./PartFive";
import year from "./../../dictionary/year";
import { Toaster, toast } from "sonner";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { postRequest, uploadFile } from "../../utils/resquests";
import Slide from "@mui/material/Slide";
import { useState } from "react";
import { UserContext } from "../../context/UserContext";
import { MuiFileInput } from "mui-file-input";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { audit_v2, upload } from "../../utils/API_urls";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewQuarterly() {
  const [releaseProduct, setReleaseProduct] = useState([]);
  const [releaseRepublic, setReleaseRepublic] = useState([]);
  const [residentalPpayroll, setResidentalPayroll] = useState("");
  const [invesment, setInvesment] = useState("");
  const [importFunds, setImportFunds] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [quarterly, setQuarterly] = React.useState("first");
  const [yearsd, setYears] = React.useState(2020);
  const { user, setUser } = React.useContext(UserContext);
  const navigate = useNavigate();
  const handleSessionTimeOut = () => {
    sessionStorage.clear();
    setUser("");
    window.location.reload(false);
  };
  const formRef = React.useRef();
  const { t } = useTranslation();
  const [addRefs, setAddRefs] = useState("no");
  const [formValid, setFormValid] = useState(false);
  const [addRefsList, setAddRefsList] = useState([]);
  const [validateRefs, setValidateRefs] = useState(true);
  const [aylanmaSoliq, setAylanmaSoliq] = useState(null);
  const [jisDaromadSoliq, setJisDaromadSoliq] = useState(null);
  const [kksSoliq, setKksSoliq] = useState(null);
  const [daromadSoliq, setDaromadSoliq] = useState(null);

  const [validateProduct, setValidateProduct] = useState(false);
  const [residentalPayroll, setResidentalPayrollV] = useState(true);

  const handleChangeAylanmaSoliq = (newValue) => {
    setAylanmaSoliq(newValue);
  };
  const handleChangeJisDaromadSoliq = (newValue) => {
    setJisDaromadSoliq(newValue);
  };
  const handleChangeKksSoliq = (newValue) => {
    setKksSoliq(newValue);
  };
  const handleChangeDaromadSoliq = (newValue) => {
    setDaromadSoliq(newValue);
  };

  const handleChangeFile = (newValue) => {
    // console.log("clicked");
    // setValue(newValue);
  };

  const handleChangeYear = (event) => {
    setYears(event.target.value);
  };
  const handleChange = (event) => {
    setQuarterly(event.target.value);
  };
  const handleChangeRefs = (event) => {
    setAddRefs(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleReport = (value, type) => {
    // uploadFile()
    const formData = new FormData();
    formData.append("file", value);
    uploadFile(upload, formData)
      .then((response) => {
        addRefsList.push({ name: type, link: response.data.link });
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          toast.success("Xatolik");
          handleSessionTimeOut();
        }
        if (error.response.data.message == "Please upload a file") {
          toast.error("Please upload a file");
        } else {
          toast.error("Serverda xatolik.");
        }
      });
  };

  const formRefSend = () => {
    // console.log(addRefs)
    if ((addRefs == "fixed_tax"||addRefs == "government") && jisDaromadSoliq) {
      handleReport(jisDaromadSoliq, "jisDaromadSoliq");
      setFormValid(true);
      setValidateRefs(true);
      toast.success("Muvaffaqiyatli!");
    } else if (addRefs!== "yes" && jisDaromadSoliq && aylanmaSoliq) {
      handleReport(aylanmaSoliq, "aylanmaSoliq");
      handleReport(jisDaromadSoliq, "jisDaromadSoliq");
      setValidateRefs(true);
      setFormValid(true);
      toast.success("Muvaffaqiyatli!");
      // handleReport(jisDaromadSoliq)
    } else if (addRefs=== "yes" && kksSoliq && jisDaromadSoliq) {
      handleReport(jisDaromadSoliq, "jisDaromadSoliq");
      handleReport(kksSoliq, "kksSoliq");
      if(daromadSoliq)
        handleReport(daromadSoliq, "daromadSoliq");
      setFormValid(true);
      setValidateRefs(true);
      toast.success("Muvaffaqiyatli!");
    } else {
      setFormValid(false);
      toast.error("Please upload a file");
      setValidateRefs(false);
    }
  };
  const commitData = () => {
    if (releaseProduct.length <= 0) {
      toast.error("Iltimos yuqoridagi fayllarni to'g'irlang");
      setValidateProduct(true);
      handleClose();
    }
    if (residentalPpayroll.length <= 0) {
      toast.error("Iltimos yuqoridagi fayllarni to'g'irlang");
      setResidentalPayrollV(false);
      handleClose();
    }
    if (addRefsList.length <= 0) {
      toast.error("Iltimos yuqoridagi fayllarni to'g'irlang");
      setValidateRefs(false);
      handleClose();
    }
    if (addRefsList.length > 0&&releaseProduct.length > 0&&residentalPpayroll.length > 0) {
      postRequest("/audit/v2", {
        name_of_report: "Choraklik hisobot",
        type_of_report: "Choraklik",
        company_id: user?._id,
        release_product: releaseProduct ? releaseProduct : null,
        release_republic: releaseRepublic ? releaseRepublic : null,
        residental_payroll: residentalPpayroll ? residentalPpayroll : null,
        invesment: invesment ? invesment : null,
        import_funds: importFunds ? importFunds : null,
        year: yearsd,
        kks_payer: addRefs,
        quarterly: quarterly,
        additional_refs: addRefsList,
      })
        .then((response) => {
          toast.success("Muvaffaqiyatli!");
          handleClose();
          navigate({
            pathname: "/user",
          });
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status == 401) {
            toast.success("Xatolik");
            handleSessionTimeOut();
          }
          handleClose();
          toast.error("Serverda xatolik.");
        });
    } else {
      handleClose();
    }
  };
  const yearList = useMemo(() => {
    return year.map((year) => {
      return { value: year.value, year_name: year["uz"] };
    });
  });
  
  return (
    <div className="main-panel report">
      <div className="content-wrapper">
        <div class="container-fluid">
          <div class="title-wrapper">
            <div class="row align-items-center">
              <div class="col-md-6"></div>
            </div>
          </div>
          <div class="form-elements-wrapper">
            <div class="row">
              <div class="col-lg-12 custom-title">
                <div class="card-style-info mb-30">
                  <h4 class="mb-25">{user?.organization_name}</h4>
                  <div class="select-style-1 card-center">
                    <div class="info-div">
                      <label className="mb-0">ИНН: </label>
                      <span>{user?.tin}</span>
                    </div>
                  </div>
                </div>
                <div class="card-style mb-30">
                  <h4 class="mb-25">{t('r_period')}</h4>
                  <div class="select-style-1">
                    <div class="select-div">
                      <label>{t('r_year')}</label>
                      <div class="select-position">
                        <select onChange={handleChangeYear}>
                          {yearList.map((elem) => {
                            return (
                              <option value={elem.value}>
                                {elem.year_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div class="select-div">
                      <label>{t('quarter')}</label>
                      <div class="select-position">
                        <select onChange={handleChange}>
                          <option value="first">1</option>
                          <option value="second">2</option>
                          <option value="third">3</option>
                          <option value="fourth">4</option>
                        </select>
                      </div>
                    </div>
                    <div class="select-div">
                      <label>{t('tax')}</label>
                      <div class="select-position">
                        <select onChange={handleChangeRefs}>
                          <option value="no">{t('button_no')}</option>
                          <option value="yes">{t('button_yes')}</option>
                          <option value="fixed_tax">{t('tax_1')}</option>
                          <option value="government">{t('government')}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <PartOne
                  setReleaseProduct={(val) => {
                    // console.log(val);
                    setReleaseProduct(val);
                  }}
                  validateProduct={validateProduct}
                />
                <PartTwo
                  setReleaseRepublic={(val) => {
                    // console.log(val);
                    setReleaseRepublic(val);
                  }}
                />
                <PartThree
                  setInvesment={(val) => {
                    // console.log(val);
                    setInvesment(val);
                  }}
                />
                <PartFour
                  setResidentalPayroll={(val) => {
                    // console.log(val);
                    setResidentalPayroll(val);
                  }}
                  validateResidentalPayroll={residentalPayroll}
                />
                <PartFive
                  setImportFunds={(val) => {
                    // console.log(val);
                    setImportFunds(val);
                  }}
                />
                <div class="card-style mb-30">
                  <h4 class="mb-25">{t('form_six')}<span className="stars-red">*</span></h4>
                  <form ref={formRef}>
                    {(addRefs === "fixed_tax"||addRefs === "government") ? (
                      <div>
                        <MuiFileInput
                          sx={{
                            mx: 1,
                            my: 1,
                            display: "inline-grid",
                            width: "auto",
                            height: "auto",
                          }}
                          label="Налог на доходы физических лиц"
                          disabled={formValid}
                          value={jisDaromadSoliq}
                          onChange={handleChangeJisDaromadSoliq}
                          placeholder="Файл *"
                        />
                      </div>
                    ) : addRefs !== "yes" ? (
                      <div className="send-form">
                        <MuiFileInput
                          sx={{
                            mx: 1,
                            my: 1,
                            display: "inline-grid",
                            width: "auto",
                            height: "auto",
                          }}
                          value={aylanmaSoliq}
                          label="Налог с оборота"
                          onChange={handleChangeAylanmaSoliq}
                          placeholder="Файл *"
                          disabled={formValid}
                          // required
                        />
                        <MuiFileInput
                          sx={{
                            mx: 1,
                            my: 1,
                            display: "inline-grid",
                            width: "auto",
                            height: "auto",
                          }}
                          value={jisDaromadSoliq}
                          label="Налог на прибыль с физических лиц"
                          onChange={handleChangeJisDaromadSoliq}
                          placeholder="Файл *"
                          disabled={formValid}
                          // required
                        />
                      </div>
                    ) : (
                      <div>
                        <MuiFileInput
                          sx={{
                            mx: 1,
                            my: 1,
                            display: "inline-grid",
                            width: "auto",
                            height: "auto",
                          }}
                          label="НДС"
                          value={kksSoliq}
                          onChange={handleChangeKksSoliq}
                          disabled={formValid}
                          placeholder="Файл *"
                        />
                        <MuiFileInput
                          sx={{
                            mx: 1,
                            my: 1,
                            display: "inline-grid",
                            width: "auto",
                            height: "auto",
                          }}
                          label="Налог на прибыль юридических лиц"
                          disabled={formValid}
                          value={daromadSoliq}
                          onChange={handleChangeDaromadSoliq}
                          placeholder="Файл"
                        />
                        <MuiFileInput
                          sx={{
                            mx: 1,
                            my: 1,
                            display: "inline-grid",
                            width: "auto",
                            height: "auto",
                          }}
                          label="Налог на доходы физических лиц"
                          disabled={formValid}
                          value={jisDaromadSoliq}
                          onChange={handleChangeJisDaromadSoliq}
                          placeholder="Файл *"
                        />
                      </div>
                    )}
                    {}
                    {validateRefs ? (
                      <></>
                    ) : (
                      <div class="warn-file">
                        {t('error_text')}
                      </div>
                    )}

                    <div class="button-add">
                      <Button
                        class="save-btn"
                        variant="contained"
                        onClick={formRefSend}
                      >
                        <i class="lni lni-save"></i>{t('button_save')}
                      </Button>
                    </div>
                  </form>
                </div>
                <div class="card-style mb-30">
                  <h4 class="mb-25">{t('form_seven')}</h4>
                  <div class="button-add">
                    <button class="save-btn" onClick={handleOpen}>
                      <i class="lni lni-upload"></i>{t('send_button')}
                    </button>
                  </div>
                </div>

                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle>
                    {"Hisobotni yuborishni tasdiqlaysizmi?"}
                  </DialogTitle>
                  <DialogActions>
                    <Button onClick={commitData}>{t('button_yes')}</Button>
                    <Button onClick={handleClose}>{t('button_no')}</Button>
                  </DialogActions>
                </Dialog>
                <Toaster richColors position="bottom-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

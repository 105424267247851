export default [
    {
        value: "2020",
        uz: "2020",
        ru: "2020",
        eng: "2020"
    },
    {
        value: "2021",
        uz: "2021",
        ru: "2021",
        eng: "2021"
    },
    {
        value: "2022",
        uz: "2022",
        ru: "2022",
        eng: "2022"
    },
    {
        value: "2023",
        uz: "2023",
        ru: "2023",
        eng: "2023"
    },
    {
        value: "2024",
        uz: "2024",
        ru: "2024",
        eng: "2024"
    }
]
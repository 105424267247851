import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { postRequest } from "../../utils/resquests";
import { release_product } from "../../utils/API_urls";
import { Toaster, toast } from "sonner";
import { TextareaAutosize } from "@material-ui/core";
import { UserContext } from "../../context/UserContext";
import { useTranslation } from "react-i18next";

const oneRowDefaultValue = {
  g1: null,
  g2: null,
  g3: null,
  g4: null,
  g5: null,
  g6: null,
  g7: null,
  f_id: uuidv4(),
};

export default function PartOne({ setReleaseProduct,validateProduct }) {
  const [listLength, setListLength] = useState([...[], oneRowDefaultValue]);
  const [disabled, setDisabled] = useState(false);
  const [error, setState] = useState(true);
  const [saveButton, setSaveButton] = useState(true);
  const { setUser } = useContext(UserContext);
  const { t } = useTranslation();
  const handleSessionTimeOut = () => {
    sessionStorage.clear();
    setUser("")
    window.location.reload(false);
  };
  useEffect(()=>{
    setState(validateProduct)
  },[validateProduct])
  const addRowHandler = (_) => {
    if (!disabled) {
      setListLength((prev) => {
        return [
          ...prev,
          {
            g1: null,
            g2: null,
            g3: null,
            g4: null,
            g5: null,
            g6: null,
            g7: null,
            f_id: uuidv4(),
          },
        ];
      });
    }
  };

  const removeRowHandler = (f_id) => {
    setListLength((prev) => {
      return prev.filter((elem) => elem.f_id !== f_id);
    });
  };

  const updateRowElem = (f_id, key, value) => {
    setListLength((prev) => {
      let row = prev.find((elem) => elem.f_id == f_id);
      row[key] = value;
      return prev;
    });
  };
  const saveRowHandler = async () => {
    let savedId = [];
    
    try {
      for (let index = 0; index < listLength.length; index++) {
        const one_row = listLength[index];
        if(!one_row.g1||!one_row.g2){
          toast.error("Serverda xatolik!");
          setState(true)
          return
        }else{
          setState(false)
        }
        const saved_one = await postRequest(release_product, {
          kind_of_activity: one_row.g1,
          OKED: one_row.g2,
          year: one_row.g3,
          quarter: one_row.g4,
          month_1: one_row.g5,
          month_2: one_row.g6,
          month_3: one_row.g7,
        });
        if (saved_one?.data?.code == 200){
          savedId.push(saved_one.data.report._id);
        }else{
          console.log(saved_one)
        }
        
      }
      setDisabled(true);
      setReleaseProduct(savedId);
      toast.success("Muvaffaqiyatli saqlandi!");
      setSaveButton(false);
    } catch (error) {
      if (error?.response?.status == 401) {
        toast.success("Xatolik");
        handleSessionTimeOut();
      }
      console.log("error")
      console.log(error)
      toast.error("Serverda xatolik!");
    }
  };

  return (
    <>
      <div className="card-style mb-30">
        <h4 className="mb-10">
          I. {t('form_one')} <span className="stars-red">*</span>
        </h4>
        <span className="right-position">({t('form_one_7')})</span>
        <table border="1">
          <thead>
            <tr className="custom-td">
              <td rowSpan="2" width="5%">
              {t('count')}
              </td>
              <td rowSpan="4" width="20%">
              {t('form_one_1')} <span className="stars-red">*</span>
              </td>
              <td rowSpan="2" width="15%">
                {t('form_one_2')} <span className="stars-red">*</span>
              </td>
              <td colSpan="3">{t('form_one_3')}</td>
              <td></td>
            </tr>
            <tr className="custom-td">
              <td>{t('form_one_4')}</td>
              <td>{t('form_one_5')}</td>
              <td rowspan="3">
                <table>
                  <thead>
                    <tr>
                      <td colSpan="3">{t('form_one_5')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1 {t('form_one_6')}</td>
                      <td>2 {t('form_one_6')}</td>
                      <td>3 {t('form_one_6')}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {listLength.map((elem, index) => {
              return (
                <PartOneRow
                  key={index}
                  row={elem}
                  ind={index}
                  removeRowHandler={removeRowHandler}
                  updateRowElem={updateRowElem}
                  disabled={disabled}
                />
              );
            })}
          </tbody>
        </table>
        {!error?(<></>):(<div class="warn-file-second">{t('error_text')}</div>)}
        {saveButton ? (
          <div className="button-add">
            <button className="add-btn" onClick={addRowHandler}>
              <i className="lni lni-plus"></i>{t('button_add')}
            </button>
            <button className="save-btn" onClick={saveRowHandler}>
              <i className="lni lni-save"></i>{t('button_save')}
            </button>
          </div>
        ) : (
          <div className="button-add">
            <button className="add-btn">{t('saved')}</button>
          </div>
        )}
      </div>
      <Toaster richColors position="bottom-right" />
    </>
  );
}

const PartOneRow = ({
  row,
  ind,
  removeRowHandler,
  updateRowElem,
  disabled,
}) => {
  return (
    <tr>
      <td>{ind + 1}</td>
      <td>
        <div class="select-position">
          <TextareaAutosize
            type="string"
            name="column1"
            className="text-area-custom"
            id="column1"
            disabled={disabled}
            onChange={(event) => {
              updateRowElem(row.f_id, "g1", event.target.value);
            }}
          />
        </div>
      </td>
      <td>
        <TextareaAutosize
          type="number"
          name="column1"
          className="text-area-custom"
          id="column1"
          disabled={disabled}
          onChange={(event) => {
            updateRowElem(row.f_id, "g2", event.target.value);
          }}
        />
      </td>
      <td>
        <TextareaAutosize
          type="text"
          className="text-area-custom"
          name="column1"
          id="column1"
          disabled={disabled}
          onChange={(event) => {
            updateRowElem(row.f_id, "g3", event.target.value);
          }}
        />
      </td>
      <td>
        <TextareaAutosize
          type="text"
          className="text-area-custom"
          name="column1"
          id="column1"
          disabled={disabled}
          onChange={(event) => {
            updateRowElem(row.f_id, "g4", event.target.value);
          }}
        />
      </td>
      <td>
        <table>
          <tbody>
            <tr className="custom-td">
              <td>
                <TextareaAutosize
                  type="text"
                  className="text-area-custom"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    updateRowElem(row.f_id, "g5", event.target.value);
                  }}
                />
              </td>
              <td>
                <TextareaAutosize
                  type="text"
                  className="text-area-custom"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    updateRowElem(row.f_id, "g6", event.target.value);
                  }}
                />
              </td>
              <td>
                <TextareaAutosize
                  type="text"
                  className="text-area-custom"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    updateRowElem(row.f_id, "g7", event.target.value);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </td>
      <td>
        <button
          className="custom-button"
          onClick={(_) => {
            if (disabled == false) {
              removeRowHandler(row.f_id);
            }
          }}
        >
          <i className="lni lni-trash-can"></i>
        </button>
      </td>
    </tr>
  );
};

import React, { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Toaster, toast } from "sonner";
import { postRequest } from "../../utils/resquests";
import { import_funds } from "../../utils/API_urls";
import { TextareaAutosize } from "@material-ui/core";
import { UserContext } from "../../context/UserContext";
import { useTranslation } from "react-i18next";

const oneRowDefaultValue = {
  g1: null,
  g2: null,
  g3: null,
  g4: null,
  g5: null,
  g6: null,
  f_id: uuidv4(),
};

export default function PartFive({ setImportFunds }) {
  const [listLength, setListLength] = useState([...[], oneRowDefaultValue]);
  const [saveButton, setSaveButton] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const { setUser } = useContext(UserContext);
  const { t } = useTranslation();
  const handleSessionTimeOut = () => {
    sessionStorage.clear();
    setUser("");
    window.location.reload(false);
  };
  const addRowHandler = (_) => {
    if (!disabled) {
      setListLength((prev) => {
        return [
          ...prev,
          {
            g1: null,
            g2: null,
            g3: null,
            g4: null,
            g5: null,
            g6: null,
            f_id: uuidv4(),
          },
        ];
      });
    }
  };

  const removeRowHandler = (f_id) => {
    setListLength((prev) => {
      return prev.filter((elem) => elem.f_id !== f_id);
    });
  };

  const updateRowElem = (f_id, key, value) => {
    setListLength((prev) => {
      let row = prev.find((elem) => elem.f_id == f_id);
      row[key] = value;
      return prev;
    });
  };

  const saveRowHandler = async () => {
    let savedId = [];
    try {
      for (let index = 0; index < listLength.length; index++) {
        const one_row = listLength[index];
        const saved_one = await postRequest(import_funds, {
          name: one_row.g1,
          cost: one_row.g2,
          unit: one_row.g3,
          qty: one_row.g4,
          acc_description: one_row.g5,
          residual_value: one_row.g6,
        });
        console.log(one_row);
        if (saved_one?.data?.code == 200)
          savedId.push(saved_one.data.report._id);
        if (saved_one?.data?.code == 401) {
          toast.success("Xatolik");
          handleSessionTimeOut();
        }
      }
      setDisabled(true);
      setImportFunds(savedId);
      toast.success("Muvaffaqiyatli saqlandi!");
      setSaveButton(false);
    } catch (error) {
      if (error?.response?.status == 401) {
        toast.success("Xatolik");
        handleSessionTimeOut();
      }
      toast.error("Serverda xatolik!");
    }
  };

  return (
    <>
      <div className="card-style mb-30">
        <h4 className="mb-10">
          V. {t('form_five')}
        </h4>
        {/* <span className="right-position">(тыс. сум)</span> */}
        <table border="1">
          <thead>
            <tr className="custom-td">
              <td rowSpan="2" width="5%">
               {t('count')}
              </td>
              <td rowSpan="2" width="15%">
                {t('form_three_1')}
              </td>
              <td rowSpan="2" width="10%">
                {t('form_four_1')}
              </td>
              <td rowSpan="2" width="15%">
                {t('form_five_1')}
              </td>
              <td rowSpan="2" width="15%">
                {t('form_five_2')}
              </td>
              <td rowSpan="2" width="15%">
                {t('form_five_3')}
              </td>
              <td rowSpan="2" width="15%">
                {t('form_five_4')}
              </td>
              <td width="2%"></td>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
      <td className="custom-td">1</td>
      <td><input type="text" name="column1" id="column1" value="" /></td>
      <td>
        <div className="select-position">
          <select>
            <option value="">Шт</option>
            <option value="">Комплект</option>
          </select>
        </div>
      </td>
      <td><input type="text" name="column1" id="column1" value="" /></td>
      <td><input type="text" name="column1" id="column1" value="" /></td>
      <td><input type="text" name="column1" id="column1" value="" /></td>
      <td><input type="text" name="column1" id="column1" value="" /></td>
      <td><button className="custom-button"><i className="lni lni-trash-can"></i></button></td>
    </tr> */}
            {listLength.map((elem, index) => {
              return (
                <PartFiveRow
                  key={index}
                  row={elem}
                  ind={index}
                  removeRowHandler={removeRowHandler}
                  updateRowElem={updateRowElem}
                  disabled={disabled}
                />
              );
            })}
          </tbody>
        </table>
        {saveButton ? (
          <div className="button-add">
            <button className="add-btn" onClick={addRowHandler}>
              <i className="lni lni-plus"></i>{t('button_add')}
            </button>
            <button className="save-btn" onClick={saveRowHandler}>
              <i className="lni lni-save"></i>{t('button_save')}
            </button>
          </div>
        ) : (
          <div className="button-add">
            <button className="add-btn">{t('saved')}</button>
          </div>
        )}
      </div>
      <Toaster richColors position="bottom-right" />
    </>
  );
}

export const PartFiveRow = ({
  row,
  ind,
  removeRowHandler,
  updateRowElem,
  disabled,
}) => {
  return (
    <tr>
      <td class="custom-td">{ind + 1}</td>
      <td>
        <TextareaAutosize
          className="text-area-custom"
          type="text"
          name="column1"
          id="column1"
          disabled={disabled}
          onChange={(event) => {
            updateRowElem(row.f_id, "g1", event.target.value);
          }}
        />
      </td>
      <td>
        <div className="select-position">
          <TextareaAutosize
            className="text-area-custom"
            type="text"
            name="column1"
            id="column1"
            disabled={disabled}
            onChange={(event) => {
              updateRowElem(row.f_id, "g2", event.target.value);
            }}
          />
        </div>
      </td>
      <td>
        <TextareaAutosize
          className="text-area-custom"
          type="text"
          name="column1"
          id="column1"
          disabled={disabled}
          onChange={(event) => {
            updateRowElem(row.f_id, "g3", event.target.value);
          }}
        />
      </td>
      <td>
        <TextareaAutosize
          className="text-area-custom"
          type="text"
          name="column1"
          id="column1"
          disabled={disabled}
          onChange={(event) => {
            updateRowElem(row.f_id, "g4", event.target.value);
          }}
        />
      </td>
      <td>
        <TextareaAutosize
          className="text-area-custom"
          type="text"
          name="column1"
          id="column1"
          disabled={disabled}
          onChange={(event) => {
            updateRowElem(row.f_id, "g5", event.target.value);
          }}
        />
      </td>
      <td>
        <TextareaAutosize
          className="text-area-custom"
          type="text"
          name="column1"
          id="column1"
          disabled={disabled}
          onChange={(event) => {
            updateRowElem(row.f_id, "g6", event.target.value);
          }}
        />
      </td>
      <td>
        <button
          className="custom-button"
          onClick={(_) => {
            if (disabled == false) {
              removeRowHandler(row.f_id);
            }
          }}
        >
          <i className="lni lni-trash-can"></i>
        </button>
      </td>
    </tr>
  );
};
